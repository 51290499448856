'use client';
import React from 'react';

interface ErrorPageProps {
  error: Error;
  reset: () => void;
}

export default function ErrorPage({ error }: ErrorPageProps) {
  // TODO: remove when this page will be ready for production
  React.useEffect(() => {
    if (!document.location.host.startsWith('local')) {
      const params = new URLSearchParams({
        503: '',
        v2: '',
        path: document.location.pathname,
        msg: error.message,
      });
      document.location.href = `https://${document.location.host}/error?${params.toString()}`;
    }
  }, []);

  return <h1 style={{ color: 'red' }}>Error: {error.message}</h1>;
}
